































































import { Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { ISideBar, IUser } from '@/types/types';
import { Getter, namespace } from 'vuex-class';
const authModule = namespace('auth');
@Component({
  name: 'SideBarDrawer',
})
export default class SideBarDrawer extends Vue {
  @Prop({ required: false, default: false }) drawer!: boolean;
  @Getter('getNavigationDrawerState') drawerState!: boolean;
  @authModule.Getter('getUserDetails') user!: IUser;
  @Prop({ required: true, default: () => [] }) links!: ISideBar[];

  // @Emit("closeOnOutsideClick")
  closeOnOutsideClick(payload: boolean) {
    this.$store.dispatch('navigationDrawer', payload, { root: true });
  }

  @Watch('drawerState')
  onDrawerChange(value: boolean) {}
}
